if (document.getElementById("product-swiper")) {
    var swiper = new Swiper("#product-swiper", {
        spaceBetween: 5,
        slidesPerView: 2,
        slidesPerGroup: 1,
        loop: false,
        // loopFillGroupWithBlank: true,

        navigation: {
            nextEl: "#product-swiper .swiper-button-next",
            prevEl: "#product-swiper .swiper-button-prev",
        },

        breakpoints: {           
            992: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        },
    });

}
if (document.getElementById("p-swiper")) {
    var swiper = new Swiper("#p-swiper", {
        spaceBetween: 20,
        slidesPerView: 2,
        slidesPerGroup: 1,
        loop: false,
        // loopFillGroupWithBlank: true,

        navigation: {
            nextEl: "#p-swiper .swiper-button-next",
            prevEl: "#p-swiper .swiper-button-prev",
        },
        breakpoints: {
            640: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 5,
            },
        },
    });
}
if (document.getElementById("ph-swiper")) {
    var swiper = new Swiper("#ph-swiper", {
        spaceBetween: 20,
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: false,
        // loopFillGroupWithBlank: true,

        navigation: {
            nextEl: "#ph-swiper .swiper-button-next",
            prevEl: "#ph-swiper .swiper-button-prev",
        }, 
        pagination: {
            el: '#ph-swiper .swiper-pagination',
            type: 'bullets',
          },    
    });
}
